<template>
  <div class="appointmentList-view">
    <div class="flex-a-b-c">
      <el-button class="v-primary" type="primary" @click="addGoodsType" :disabled="!hasPermi">新建分类</el-button>
      <div>
        <!-- <el-select v-model="value" placeholder="请选择" style="width: 240px">
          <el-option value="" label="全部" class="cbcc">全部</el-option>
          <el-option
            v-for="item in optionList"
            :key="item.productsTypeId"
            :label="item.productsTypeName"
            :value="item.productsTypeId"
          >
          </el-option>
        </el-select> -->
        <el-input
             v-model="searchKey"
             class="mb10"
             style="width: 240px"
             placeholder="请输入分类名称"
          />
        <el-button class="v-primary ml10" type="primary" @click="queryAppointmentPage">查询</el-button>
      </div>
    </div>

    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="currentPage"
                 :loading="loading"
                 :total="total">
      <template v-slot:table>
        <el-table-column prop="productsTypeId" width="100" align="center" label="分类ID" />
        <el-table-column
          prop="productsTypeName"
          align="center"
          label="分类名称"
          show-overflow-tooltip
        />
        <el-table-column prop="productsCount" align="center" label="商品数量" />
        <el-table-column align="center" label="操作">
           <template slot-scope="scope">
            <el-button type="text" class="mr10 hover_pointer cblue" @click="editType(scope.row.productsTypeId)" :disabled="!hasPermi">编辑</el-button>
            <el-button type="text" class="hover_pointer corange" @click="deleteType(scope.row.productsTypeId)" :disabled="!hasPermi">删除</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="排序">
          <template slot-scope="scope">
            <span class="el-icon-upload2 mr10 hover_pointer" style="font-size:20px" @click="goodsSort('up',scope.row.productsTypeId)"></span>
            <span class="el-icon-download hover_pointer" style="font-size:20px" @click="goodsSort('down',scope.row.productsTypeId)"></span>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  fetchProductsTypePage,
  fetchProductsTypeList,
  alterAppointmentGoodsTypeSort,
  addAppointmentGoodsType,
  delProductsType
} from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils";
export default {
  name: "appointmentList",
  components: {
    commonTable,
  },
  data() {
    return {
      hasPermi: true,
      userInfo: this.$store.state.loginRoot.userInfo,
      value: "",
      tableData: [],
      optionList:'', //全部分类
      loading:false,
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      searchKey:'' //关键字
    };
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  props:{
    jurisdiction:{
        type: Boolean,
        default: false,
    },
  },
  mounted(){
    this.getTableData()
    this.getTypeList()
  },
  created(){
    if(JSON.parse(localStorage.getItem('isSourceAdmin'))) {
      this.hasPermi = false
    }
  },
  methods:{
    //请求预约商品列表数据
    getTableData() {
      this.loading = true;
      let data = {
        ignore: true,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        productsTypeId: this.value,
        productsTypeName:this.searchKey
      };
      fetchProductsTypePage(data)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.pageInfo.list || "";
          this.total = res.data.pageInfo.total;
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
          console.log(err);
        });
    },
    //查询分类
    getTypeList() {
      fetchProductsTypeList({ ignore: true })
        .then((res) => {
          this.optionList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //筛选查询符合条件数据
    queryAppointmentPage() {
      
      this.currentPage = 1;
      this.pageNum = 1;
      this.loading = true;
      this.getTableData();
    },
    //修改排序
    goodsSort(type,id) {
      if(this.userInfo.roleId || this.jurisdiction){
        let data = ''
        if(type == 'up'){
          data = {
            productsTypeId: id,
            type: 1,
          }
        }else{
          data = {
            productsTypeId: id,
            type: 2,
          }
        }
        alterAppointmentGoodsTypeSort(data)
          .then((res) => {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.loading = true;
            this.getTableData()
          })
          .catch((err) => {
            console.log(err);
          });
      }else{
        this.$message({
          type: "warning",
          message: "暂无权限",
        });
        return
      }
      
    },
    //新增
    addGoodsType(){
      if(this.userInfo.roleId || this.jurisdiction){
        this.$prompt("新增分类", "提示", {
          onConfirmButtonText: "确定",
          cancelButtonText: "取消",
        })
        .then(({ value }) => {
          let data = {
            productsTypeName: value,
          };
          
          addAppointmentGoodsType(data)
          .then(res=>{
            this.$message({
              type: "success",
              message: res.message,
            });
            this.searchKey = ''
            this.getTableData()
          });

        })
        .catch((err) => {
          console.log(err);
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
      }else{
        this.$message({
          type: "warning",
          message: "暂无权限",
        });
        return
      }
      
    },
    //修改
    editType(id){
      if(this.userInfo.roleId || this.jurisdiction){
        this.$prompt("编辑分类", "提示", {
          onConfirmButtonText: "确定",
          cancelButtonText: "取消",

        })
        .then(({ value }) => {
          let data = {
            productsTypeId:id,
            productsTypeName: value,
          };
          addAppointmentGoodsType(data)
          .then(res=>{
            this.$message({
              type: "success",
              message: res.message,
            });
            this.searchKey = ''
            this.getTableData()
          });

        })
        .catch((err) => {
          console.log(err);
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
      }else{
        this.$message({
          type: "warning",
          message: "暂无权限",
        });
        return
      }
      
    },
    //删除
    deleteType(id){
      if(this.userInfo.roleId || this.jurisdiction){
        this.$confirm("此操作将永久删除该分类, 是否继续?", "提示", {
          onConfirmButtonText: "确定",
          cancelButtonText: "取消",
          type: 'warning'
        })
        .then(( ) => {
          let data = {
            productsTypeId:id,
          };
          delProductsType(data)
          .then(res=>{
            this.$message({
              type: "success",
              message: res.message,
            });
            this.searchKey = ''
            this.getTableData()
          });

        })
        .catch((err) => {
          console.log(err);
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
      }else{
        this.$message({
          type: "warning",
          message: "暂无权限",
        });
        return
      }
      
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.loading = true;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.loading = true;
      this.getTableData();
    },
  }
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>